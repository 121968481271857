import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Pictures and videos are a very strong medium and have a high influence on
decisions. Open the activity, click on “Images”, and drag and drop a picture
from your computer. The first picture you upload is going to be the
cover picture:`}</p>
    <Image src="pro/addimage.jpg" mdxType="Image" />
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Show pictures that represent the character of the trail, or the
uniqueness of the landscape and experience.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use pictures that contain people doing the activity you want to present.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use high quality pictures. The quality of a picture is not only made by
its resolution, it's made by composition, lighting and the action of
the subjects.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`We recommend to upload more than one picture to an activity.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can also embed Youtube videos in the trail description. Open the
“Advanced” options in the trail editor and enter the URL of your video.`}</p>
      </li>
    </ul>
    <br />
    <br />
    <Link className="float-left" to="/pro/touraccess" mdxType="Link">
  ← Tours and access
    </Link>
    <Link className="float-right" to="/pro/transfer" mdxType="Link">
  Transfer your content to your destination →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      